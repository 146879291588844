<template>
  <div class="visit-temporary-new-edit">
    <div class="card">
      <van-row class="data">
        <van-col span="12">
          基本信息录入
        </van-col>
      </van-row>
    </div>
    <div>
      <van-form @submit="addPlan()">
        <van-field required :rules="costomerForm.customerName" label="客户名称" placeholder="客户名称"
                   v-model="data.customerName"></van-field>
        <van-field
            label="客户类型"
            center
            autocomplete="off"
            placeholder="请选择（必填）"
        >
          <template #input>
            <el-select v-model="data.cusType" placeholder="请选择客户类型" size="mini">
              <el-option
                  v-for="item in cusTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </van-field>
        <van-field label="地址性质" placeholder="地址性质" v-model="data.addressNature">
          <template #input>
            <el-select v-model="data.addressNature" placeholder="请选择地址性质" size="mini">
              <el-option
                  v-for="item in addressNatureOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </van-field>
        <van-field label="企业性质" placeholder="企业性质" v-model="data.enterpriseNature">
          <template #input>
            <el-select v-model="data.enterpriseNature" placeholder="请选择企业性质" size="mini">
              <el-option
                  v-for="item in enterpriseNatureOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </van-field>
        <van-field label="拜访类型" placeholder="拜访类型" v-model="data.visitType">
          <template #input>
            <el-select v-model="data.visitType" placeholder="请选择拜访类型" size="mini">
              <el-option
                  v-for="item in visitTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </van-field>
        <van-collapse v-model="activeNames" style="text-align: left;" accordion>
          <van-collapse-item title="联系人信息" name="1">
            <van-field label="职务" placeholder="职务"
                       v-model="data.post"></van-field>
            <van-field label="联系人" placeholder="联系人"
                       v-model="data.contact"></van-field>
            <van-field label="电话" placeholder="电话"
                       v-model="data.tel"></van-field>
            <van-field label="地址" placeholder="地址"
                       v-model="data.addressDetailInfo.address" @click="showMap"></van-field>
          </van-collapse-item>
        </van-collapse>
        <van-field required :rules="costomerForm.customerName" label="走访名称" placeholder="走访名称"
                   v-model="data.title"></van-field>
        <van-field
            readonly
            required
            clickable
            name="datetimePicker"
            :value="data.date"
            label="计划日期"
            placeholder="点击选择时间"
            @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
              type="date"
              :maxDate="maxDate"
              v-model="currentDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>
<!--        <van-field required type="textarea" label="目标" :rules="costomerForm.target" placeholder="目标" v-model="data.target"></van-field>-->
<!--        <van-field required type="textarea" label="现状" :rules="costomerForm.presentSituation" placeholder="现状"-->
<!--                   v-model="data.presentSituation"></van-field>-->
<!--        <van-field required type="textarea" label="计划方案" :rules="costomerForm.plan" placeholder="计划方案" v-model="data.plan"></van-field>-->
        <van-row v-if="data.interviewType">
          <van-field
              readonly
              required
              clickable
              label="走访类型："
              input-align="left"
              :value="data.interviewType"
              :rules="customerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
              label-width="6.5em"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field v-if="data.goalPlan" required autosize type="textarea" label="目标及方案" placeholder="目标及方案" :rules="customerForm.goalPlan"
                   v-model="data.goalPlan === 'null'?'':data.goalPlan" label-width="6.5em"></van-field>
        <van-field required type="textarea" label="准备怎么做" :rules="costomerForm.getReady" placeholder="准备怎么做"
                   v-model="data.getReady"></van-field>
        <van-field type="textarea" label="备注" placeholder="备注" v-model="data.remarks"></van-field>
        <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
    </div>
    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.data.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";
import CrmTabBar from "@/components/CrmTabBar";
export default {
  name: "BulkCrmsVisitTemporaryNewEdit",
  components: {CrmTabBar, BMapAddressSelect},
  data() {
    return {
      activeNames: 0,
      cusTypeOptions: [{
        value: 'HF',
        label: '货方'
      }, {
        value: 'CF',
        label: '船方'
      }, {
        value: 'GF',
        label: '港方'
      }, {
        value: 'QT',
        label: '其他'
      }],
      addressNatureOptions: [
        {
          value: '',
          label: ''
        },
        {
          value: '01',
          label: '总公司地址'
        }, {
          value: '02',
          label: '办公室地址'
        },
      ],
      enterpriseNatureOptions: [
        {
          value: '',
          label: ''
        },
        {
          value: '01',
          label: '国企'
        }, {
          value: '02',
          label: '私企'
        },
      ],
      visitTypeOptions: [
        {
          value: '',
          label: ''
        },
        {
          value: '01',
          label: '国企'
        }, {
          value: '02',
          label: '私企'
        },
      ],
      isHidden: false,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      currentDate: new Date(),
      data: {
        customerName: '',
        cusType: '货方',
        addressNature: '',
        enterpriseNature: '',
        visitType: '',
        post: '经理',
        contact: '胡锦',
        tel: '12345678911',
        addressDetailInfo: {
          address: '中国上海市',
          longitude: '',
          latitude: '',
          province: '',
          city: '',
          district: ''
        },
        title: '山东华海路云',
        date: this.formatter(),
        target: '见面认识',
        presentSituation: '打过电话，无合作',
        plan: '实地走访',
        getReady: '中允86，44498，浅吃水10.5，不过江阴，里面仨月没去强制引航不合适，南线不限主要看价格。市场货少，也参与投标。',
        remarks: '',
      },
      options: [],
      value: [],
      list: [],
      loading: false,
      costomerForm: {
        customerName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        cusType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        addressNature: [
          {required: true, message: '', trigger: 'blur'},
        ],
        enterpriseNature: [
          {required: true, message: '', trigger: 'blur'},
        ],
        visitType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '', trigger: 'blur'},
        ],
        presentSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      showInterviewTypePicker: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getBulkCrmsCusList.json',
          {
            cusType: '',
            cusNature: '',
            cusLevel: '',
            customerName: '',
            isArrow: false
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          for (var i = 0; i < res.data.length; i++) {
            let array = {};
            array.value = res.data[i].customerName;
            array.label = res.data[i].customerName;
            array.id = res.data[i].id;
            this.list.push(array)
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
      console.log(this.list)
    },
    to(to) {
      this.$router.push(to)
    },
    onCancel() {

    },
    addPlan(formName) {
      setTimeout(() => {
        this.$router.push({path: '/BulkCrmsTravelPlanDetail'})
      }, 200);
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    onConfirm(time) {
      this.info.date = this.formatter(time);
      this.showPicker = false;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleSelectName(item) {
      this.info.title = item.value
    },
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.data.addressDetailInfo = addressDetailInfo;
    },
    showPopup() {
      sessionStorage.setItem('pageType', 'Edit')
      this.$router.push({path: '/BulkCrmsCusAddressDetailList/' + this.$route.params.name})
    },
    onClickType() {
      this.showInterviewTypePicker = true
      this.data.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      this.data.interviewType = picker;
      this.showInterviewTypePicker = false;
    },
  }
}
</script>

<style scoped>

.data {
  text-align: left;
  padding: 7px 15px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.visit-temporary-new-edit {
  overflow-y: scroll;
  height: 1000px;
}

.visit-temporary-new-edit .card {
  background: #318EF5;
}
</style>

<style>
.visit-temporary-new-add .el-input__inner {
  border: 0px solid #DCDFE6;
}
</style>